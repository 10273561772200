import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Hero from "../components/hero";
import HorizontalTestimonial from "../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../components/hero-end";

const ExtravertsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				pierreB: file(relativePath: { eq: "testimonials/Pierre Bauzee.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				benG: file(relativePath: { eq: "testimonials/Ben Gottlieb.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const benG = data.benG.childImageSharp.gatsbyImageData;
	const title = "Extraverts | Business & Executive Coaching in London";
	const description =
		"For extraverted business owners, you thrive on interaction and are good at promoting your services or products using your social skills and expansive network to drive your business.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const pierreB = data.pierreB.childImageSharp.gatsbyImageData;

	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Extraverts Executive Coaching in London",
				item: {
					url: `${siteUrl}/extraverts`,
					id: `${siteUrl}/extraverts`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/extraverts`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Extraverts Executive Coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Extraverts"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								For extraverted business owners, you thrive on interaction and
								are good at promoting your services or products using your
								social skills and expansive network to drive your business.
								However, to truly excel in the competitive business world,
								mastering business management is just as crucial.{" "}
							</p>
							<p>
								A business and executive coach is an invaluable resource for
								extraverts in business. Working with a coach provides you with
								tailored support and guidance to develop effective strategies
								that enhance profitability and generate sustainable growth.{" "}
							</p>
							<p>
								Coaches play a pivotal role in helping you focus your natural
								extraverted strengths on critical business activities,
								optimising operations and aligning your offerings with market
								demands to stay ahead in your industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Bespoke and Personalised Coaching for Extraverts
							</h3>
							<p>
								Extraverted professionals often thrive in environments that
								require networking, self-promotion, and dynamic marketing, using
								their outgoing nature to engage effectively with clients and
								expand their business networks. However, extraverts can face
								challenges when it comes to fine-tuning aspects of their
								business, such as setting appropriate pricing, administration,
								maximising profitability, delegation, developing innovative
								services and creating marketing strategies that bring in your
								ideal clients.
							</p>
							<p>
								With over forty years of business experience and a focused
								specialisation in coaching for the past seven, I have lots of
								experience in assisting extraverted professionals to enhance
								their business. Our coaching sessions are tailored to address
								specific challenges, the addition of new technologies, adapting
								to rapidly changing client preferences and navigating the
								competitive demands of today's market.{" "}
							</p>
							<p>
								Whether you’re aiming to refine your existing business model or
								are eager to explore a new venture, I'm here to guide you
								through this transformative journey with effective business and
								executive coaching tailored to your unique needs.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								How Business Coaching can bring a new perspective to your
								business
							</h3>

							<p>
								A business coach can provide significant value to your business,
								particularly when partnering with one who understands the
								dynamic strengths and weaknesses of extraverted personalities in
								the business world.
							</p>
							<p>
								Imagine transforming your business with strategies that play to
								your extraverted strengths while achieving your professional
								goals:
							</p>
							<ul className="pl-4">
								<li>
									<strong>Develop a targeted strategy: </strong>Utilise your
									natural charisma to engage a broader audience, crafting
									marketing efforts that are as outgoing and impactful as you
									are.
								</li>
								<li>
									<strong>Expand your business offerings: </strong>Consider
									diversifying your services to include dynamic, interactive
									experiences like workshops, live events, or networking mixers
									that showcase your ability to thrive in social settings and
									engage directly with clients.
								</li>
								<li>
									<strong>Optimise pricing: </strong>With the help of a business
									coach, adjust your pricing structure to reflect the engaging,
									high-energy quality of your services and the unique value you
									bring to each interaction.
								</li>
								<li>
									<strong>Expand your team: </strong>Learn to build a team that
									compliments your strengths, matches your energy and enhances
									your business’s operational capabilities.
								</li>
								<li>
									<strong>Engage with a business coach: </strong>Partnering with
									a business coach to guide you through the complexities of the
									business world, ensuring you make the most of your natural
									abilities to network, energise, and inspire.
								</li>
							</ul>
							<p>
								Let's connect and kickstart this transformational journey
								together, maximising your potential as an extraverted
								entrepreneur.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So, what’s been stopping you?</h2>
							<p>
								As an extravert, the vibrant energy you bring to every
								interaction is a significant asset in the business world. Yet,
								the continuous demands of deadlines and client expectations make
								it challenging to work on the business rather than in the
								business.{" "}
							</p>
							<p>
								Partnering with a specialised Business & Executive Coach for
								extraverts will provide the support you need to navigate these
								challenges with confidence and energy:
							</p>
							<ul className="pl-4">
								<li>
									<strong>Strategic expansion: </strong>Receive personalised
									guidance on how to broaden your service offerings and tap into
									new market opportunities.
								</li>
								<li>
									<strong>Pricing mastery: </strong>Gain expert advice on
									refining your pricing strategies to ensure they reflect the
									true value of your work and enhance your profitability.
								</li>
								<li>
									<strong>Innovative product development: </strong>Get support
									in enhancing your existing services or developing new,
									innovative offerings that set you apart from the competition.
								</li>
								<li>
									<strong>Effective Team Dynamics: </strong>Build and manage a
									vibrant team that matches your energy and supports your
									professional goals.
								</li>
							</ul>
							<p>
								Now is the perfect time to leverage your extraverted traits with
								the guidance of an experienced, impartial, and confidential
								business coach. Let’s embrace your outgoing nature and push the
								boundaries of what you and your business can achieve.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Empowering your business with business and executive coaching
							</h2>
							<p>
								As an experienced business and executive coach, I provide
								personalised coaching specifically designed for extraverted
								professionals. Partnering with me provides you with bespoke
								sessions aimed at enhancing both your personal growth and
								professional advancement. Together, we will delve into the
								complexities of your field, helping you stay ahead of industry
								trends and developments.
							</p>
							<p>
								In our sessions, we will refine your leadership techniques to
								maximise your extraverted traits effectively. We will also
								develop a business plan that resonates with your goals and then
								work together to make it happen.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								As a business coach specialising in supporting extraverted
								entrepreneurs, my focus is on utilising your natural strengths
								to not only excel in your business endeavours but also to ensure
								that you are profitable and sustainable. Our coaching sessions
								offer a secure and confidential environment where you can freely
								discuss matters.
							</p>
							<p>
								Extraverts possess a unique set of qualities that are highly
								beneficial in the business world. Your outgoing nature, ease in
								social situations, and ability to energise yourself and others
								are great assets. You thrive in environments that require
								networking, team collaboration, and client relations which are
								key components of successful entrepreneurship.
							</p>
							<p>
								In our coaching relationship, we will leverage your extraverted
								traits to boost your business’s visibility, enhance team
								dynamics, and improve customer engagement. We’ll work on
								strategic planning that aligns with your approach, focusing on
								expanding your network and maximising opportunities for growth
								and innovation.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Pierre Bauzee. Beyond Satisfaction Ltd. Customer Service Specialists."
						review="Jason is such a great coach and has been an amazing help and support for me and my business on so many aspects. Definitely recommended! Thank you again Jason."
						linkedIn="https://www.linkedin.com/in/pierre-bauz%C3%A9e-233b058b/"
						profilePic={pierreB}
					/>

					<HorizontalTestimonial
						name="Ben Gottlieb. Owner at Gottlieb Law PLC"
						review="I have enjoyed working with Jason Cornes and he is an excellent business coach. As a U.S. business, I was not sure how it would work out with a U.K. business coach, but I couldn’t be happier. Jason has served as a wonderful resource to me and the firm and is always someone I can count on and puts 100% into his clients."
						linkedIn="https://www.linkedin.com/in/ben-gottlieb-038a8550/"
						profilePic={benG}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a discovery call together</h3>
							<p>
								Why not book a discovery call with me? This relaxed conversation
								offers the ideal opportunity for us to get to know each other
								and explore how personalised business and executive coaching can
								work for you.
							</p>
							<p>
								Let’s discover how you can use your natural extroverted
								qualities to transform your professional dreams into a thriving
								business reality.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default ExtravertsCoachingPage;
